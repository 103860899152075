@charset 'UTF-8';
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_burger.scss";
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_flex.scss";
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_media-queries.scss";
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_mixin.scss";

.page-404 {
  padding: 170px 0;
  @include SP {
    padding: 100px 0 80px;
  }
  .cm-ttl01 {
    margin-bottom: 30px;
  }
  p {
    text-align: center;
    margin-bottom: 30px;
    @include SP {
      @include font(14,30);
    }
  }
}